<template>
    <div class="card">
        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="false"
                title="Product List"
            />
        </div>

        <div class="row p-2">
            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Product Type</label>
                <v-select
                    v-model="product_type"
                    :options="productTypeArray"
                    label="value"
                    :reduce="value => value.key"
                    placeholder="Select Product Type"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Product Name</label>
                <AsyncSelect
                    placeholder="Select Product"
                    v-model="product_id"
                    label="name"
                    :api-service="fetchEndpointProductList"
                    :reduce="name => name.id"
                    :additional-query="{ product_type: product_type ? product_type : '' }"
                    ref="productField"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Short Description</label>
                <input
                    v-model.trim="short_description"
                    class="form-control"
                >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                <label class="form-label" for="select2-basic">Full Description</label>
                <input
                    v-model.trim="full_description"
                    class="form-control"
                >
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-4">
                <label class="form-label" for="select2-basic">Supplier</label>
                <AsyncSelect
                    placeholder="Supplier Name, ID, Mobile No"
                    v-model="contactProfile"
                    :api-service="fetchContactProfiles"
                    :additional-query="{'type' : 'supplier'}"
                    :format-label="formatPatientLabel"
                />
            </div>

            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-auto">
                <button @click="getProducts" class="btn btn-primary waves-effect waves-float waves-light">
                    Go
                </button>
                <button @click="exportReport" class="btn btn-block btn-success ms-1 waves-effect waves-float waves-light"
                >
                    Export
                </button>
            </div>
            <div class="col-12 col-sm-6 col-md-4 col-lg-3 mt-auto">
                
            </div>
        </div>

        <div class="col-12 px-2 my-2">
            <ListTable :tableItems="tableItems"/>
        </div>

        <div class="px-2 position-absolute bottom-0">
            <Pagination
              :offset="10"
              @onPageChange="onPageChange"
              ref="pagination"
            />
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted, computed } from 'vue';
    import TitleButton from '@/components/atom/TitleButton';
    import handleInventory from "@/services/modules/inventory";
    import AsyncSelect from "@/components/molecule/input-field/AsyncSelect.vue";
    import {useRoute, useRouter} from 'vue-router'
    import Pagination from "@/components/atom/Pagination.vue";
    import ListTable from '@/components/molecule/company/hospital/service-resources/ProductListTable.vue';
    import {useStore} from "vuex";
    import {useAsyncDropdownHelper} from "@/services/utils/asyncDropdownHelper";
    import handleContact from '@/services/modules/contact'

    const { fetchHome, fetchEndpointProductList, fetchEndpointProductDetails } = handleInventory();
    const $route = useRoute();
    const $router = useRouter();
    const $store = useStore();

    const productTypeArray = ref([]);
    const product_type = ref(null);
    const product_id = ref(null);
    const short_description = ref('');
    const full_description = ref('');
    const contactProfile = ref(null);
    const tableItems = ref([]);
    const page = computed(() => $route.query.page);
    const offset = ref(10);
    const { fetchContactProfiles } = handleContact();
    const { formatPatientLabel } = useAsyncDropdownHelper();

    onMounted(() => {
        Promise.all([
            fetchHome().then((res) => {
                if(res.status) {
                    let productType = res.data.product_type
                    productTypeArray.value = Object.keys(productType).map(key => ({
                                                key,
                                                value: productType[key],
                                            }));
                }
            })
        ]).catch(e => showError('Something went wrong'))
    })

    const getQuery = () => {
        let query = '';
        query += `?company_id=${$route.params.companyId}`;
        query += '&offset=' + offset.value
        if (page.value) query += '&page=' + page.value
        if(product_id.value) query += `&product_id=${product_id.value}`;
        if(product_type.value) query += `&product_type=${product_type.value}`;
        if(short_description.value) query += `&short_description=${short_description.value}`;
        if(full_description.value) query += `&full_description=${full_description.value}`;
        if(contactProfile.value) query += `&contact_profile_id=${contactProfile.value.id}`;
        
        return query;
    }

    const getProducts = async(resetPage = true) => {
        if(resetPage) {
            let routeQuery = Object.assign({}, $route.query)
            routeQuery.page = 1
            await $router.push({path: $route.path, query: routeQuery})
        }

        let query = getQuery();

        fetchEndpointProductDetails(query).then((res) => {
            tableItems.value = res.data.data;
            setPagination(res.data)
        })
    }

    const setPaginationData = (data) => {
        $store.commit('setPaginationData', data)
    }

    const setPagination = (data) => {
        setPaginationData({
          records: data.total,
          perPage: data.per_page,
          currentPage: data.current_page,
        })
    }

    const onPageChange = async (page) => {
        let routeQuery = Object.assign({}, $route.query)
        routeQuery.page = page
        await $router.push({path: $route.path, query: routeQuery})
        getProducts(false);
    }

    const exportReport = () => {
        let token = localStorage.getItem('token');

        let url = `${process.env.VUE_APP_BASE_URL}/export/v2/inventory/products/list/endpoint/details${getQuery()}&_token=${token}&is_export=1`;
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.click();
    }

</script>