<template>
    <div class="card card-height">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>Type</th>
            <th>Product Name</th>
            <th>Short Description</th>
            <th>Full Description</th>
            <th>Supplier</th>
            <th>Action</th>
            <th>Sku</th>
            <th>Base Unit</th>
            <th class="text-right">Sales Price</th>
            <th>Recorder Level</th>
            <th>Stock In Hand</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, i) in tableItems" :key="i">
            <td> {{ item.product_type }}</td>
            <td> {{ item.name }}</td>
            <td> {{ item.short_description }}</td>
            <td> {{ item.full_description }}</td>
            <td> {{ item.supplier }}</td>
            <td>
              <button class="btn btn-secondary btn-sm"
                @click="gotToProductEdit(item.id, item.product_type)"
                style="cursor: pointer; margin-left: 10px"
              >
                <i class="fas fa-edit"></i>
              </button>
            </td>
            <td> {{ item.sku }}</td>
            <td> {{ item.description.unit.name }}</td>
            <td class="text-right"> {{ item.description.sales_price }}</td>
            <td> {{ item.recorder_level }}</td>
            <td> {{ item.stock_in_hand }}</td>
          </tr>
  
          </tbody>
        </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
      </div>

      <ProductEditModal
        :accountHeads="accountHeads"
        :selectedSpecifications="selectedSpecifications"
        :specifications="specifications"
        :clue="clue"
        :units="units"
        :group="productGroup"
        :formData="productSingle"
        :inventoryMethods="inventoryMethods"
        :productSingleOriginal="productSingleOriginal"
        :setBrandValue="setBrandValuetoEditform"
        :removeBrandValue="removeBrandValue"
        @onCloseModal="onCloseModal"
        @onClickUpdateProduct="handleProductUpdate"
        ref="productedit"
      />
    </div>
  </template>
  
<script setup>
    import { inject, ref, onMounted } from 'vue';
    import handleInventory from '@/services/modules/inventory'
    import {useRoute, useRouter} from 'vue-router'
    import ProductEditModal from '@/components/molecule/company/inventory/product-service/ProductEditModal'

    const emitter = inject('emitter');
    const showError = inject("showError");
    const showSuccess = inject("showSuccess");

    const props = defineProps({
      tableItems: Array
    })

    const { fetchAccountHeads, fetchClue, fetchUnit, fetchGroup, fetchHome, getProduct, updateProduct } = handleInventory();
    const $route = useRoute();
    const $router = useRouter();

    const accountHeads = ref([]);
    const clue = ref([]);
    const units = ref([]);
    const productGroup = ref([]);
    const homeContent = ref({});
    const inventoryMethods = ref([]);
    const productSingleOriginal = ref({});
    const productSingle = ref({});
    const selectedSpecifications = ref([]);
    const specifications = ref({});

    const companyId = $route.params.companyId;

    onMounted(() => {
      getAccountHeads();
      getClue();
      getUnit();
      getHomeContent();
    })

    const handleProductUpdate = async() => {
      try {
          if(productSingle.value.image == null) delete productSingle.value.image
          if(productSingle.value.inventory_method == null) delete productSingle.value.inventory_method
          if(productSingle.value.additional_images.length === 0) delete productSingle.value.additional_images

          let fd = new FormData();
          Object.keys(productSingle.value).forEach(key => {
              if(
                  ! ["additional_images", "specifications", "alternative_unit_ids"].includes(key) &&
                  productSingle.value[key] !== null
              ) {
                  fd.append(key, productSingle.value[key])
              }
          })

          if(productSingle.value.additional_images) {
              for(let i=0; i < productSingle.value.additional_images.length; i++) {
                  let key = `additional_images[${i}]`;
                  let value = productSingle.value.additional_images[i];
                  fd.append(key, value);
              }
          }
          
          const alternativeUnitIds = productSingle.value.alternative_unit_ids.map(unitId => {
              return {
                  unit_id: unitId
              }
          })

          fd.append('specifications', JSON.stringify(productSingle.value.specifications))
          fd.append('alternative_unit_ids', JSON.stringify(alternativeUnitIds))
          let res = await updateProduct(fd, productSingle.value.id)

          if(res.status) {
            showSuccess(res.message)
            emitter.emit('modalOpen')
          }
      } catch (err) {
          if(!err.response) {
            showError('Something is wrong. Check your connectivity!!')
          }
      }
    }

    const gotToProductEdit = (id, type) => {
      getSingleProduct(id);
      getGroupData(type);
      emitter.emit('modalOpen')
    }

    const getGroupData = (type) => {
      fetchGroup(companyId, type).then((res) => {
        productGroup.value = res.data;
      }).catch(e => console.log('Something went wrong'))
    }

    const getSingleProduct = (id) => {
      getProduct({id: id, company_id: companyId}).then((res) => {
        productSingleOriginal.value = res.data;
        prepareProductEditData(res.data);
      }).catch(e => console.log('Something went wrong'))
    }

    const getAccountHeads = () => {
      fetchAccountHeads(companyId).then((res) => {
        accountHeads.value = res.data
      }).catch(e => console.log('Something went wrong'))
    }

    const getClue = () => {
      fetchClue(companyId).then((res) => {
        clue.value = res.data
      }).catch(e => console.log('Something went wrong'))
    }

    const getUnit = () => {
      fetchUnit(companyId).then((res) => {
        units.value = res.data;
      }).catch(e => console.log('Something went wrong'))
    }

    const getHomeContent = () => {
      fetchHome().then((res) => {
        homeContent.value = res.data
        prepareInventoryMethods();
      }).catch(e => console.log('Something went wrong'))
    }

    const prepareInventoryMethods = () => {
      inventoryMethods.value = [];
          Object.keys(homeContent.value.inventory_methods).forEach((val)=>{
            inventoryMethods.value.push({
              id : val,
              name : homeContent.value.inventory_methods[val],
          })
      });
    }

    const setBrandValuetoEditform = (name, id) => {
      productSingle.value.specifications[name] = id
    }

    const removeBrandValue = (name) => {
      delete productSingle.specifications[name]
    }

    const onCloseModal = () => {
      selectedSpecifications.value = []
      productSingle.value.specifications = {}
    }

    const prepareProductEditData = (data) => {
      let editData = {
        id: data.id,
        company_id: companyId,
        product_head_id: data.product_id,
        name: data.name,
        specifications: {},
        unit_id: data.description.unit.id,
        purchase_price: data.description.purchase_price,
        purchase_account_head_id: data.description.purchase_account_head.id,
        sales_price:  data.description.sales_price,
        sales_account_head_id: data.description.sales_account_head.id,
        cost_of_sales: data.description.cost_of_sales,
        cost_of_sales_account_head_id: data.description.cost_of_sales_account_head.id,
        inventory_account_head_id: data.description.inventory_account_head.id,
        default_purchase_unit_id: data.description.default_purchase_unit_id,
        default_sales_unit_id: data.description.default_sales_unit_id,
        default_shipping_unit_id: data.description.default_shipping_unit_id,
        sku: data.sku,
        short_description: data.short_description,
        full_description: data.full_description,
        image: null,
        status: 'active',
        additional_images: [],
        vat_rate: data.description.vat_rate,
        alternative_units: data.alternative_units,
        inventory_method: data.description.inventory_method,
        _method: 'PATCH'
      }
      productSingle.value = editData
    }
  
</script>

  